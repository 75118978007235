<script>
import Layout from "../../../layouts/main";
const Area = require('./../../../../api/area');
const SoalType = require('./../../../../api/soalType');
const Soal = require('./../../../../api/soal');
const Matpel = require('./../../../../api/matpel');
const Jenjang = require('./../../../../api/jenjang');
import {Circle8} from 'vue-loading-spinner'
// import tinymce from 'vue-tinymce-editor'

export default {
  // components: { Layout,Circle8, tinymce },
    components: { Layout,Circle8 },
    page: {
        title: "Soal",
        meta: [{ name: "description", content: "List Of Schools Area" }]
    },
    data() {
        return {
            fieldSoal :[
                {
                    id:0,
                    soal : "<p>sdsd</p>",
                    soal_dua : "",
                    soal_tiga : "",
                    jawaban : null,
                    imlaKecil : null,
                    isError : false,
                    errorMessage : "",
                    isEssay : false,
                    isFile : false,
                    isVoice : false,
                    imgPembahasan : false,
                    textPembahasan : "",
                    choices : [
                        {
                            id : "A",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "B",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "C",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "D",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        }
                    ]
                }
            ],
            id:null,
            types : [],
            type : null,
            matpels : [],
            matpel : 0,
            name :'',
            loading : false,
            isError :false,
            message : '',
            Area : new Area(),
            soalType : new SoalType(),
            Matpel : new Matpel(),
            Soal : new Soal(),
            Jenjang : new Jenjang(),
            jenjangs : [],
            id_jenjang : 0
        }
    },
    mounted(){
        this.getType();
    },
    methods: {
        getType(){
            this.soalType.pondok(1)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.types = res.data_paket.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },

        TambahSoal(){
            this.fieldSoal.push({
                    id:this.fieldSoal.length,
                    soal : "",
                    soal_dua : "",
                    soal_tiga : "",
                    jawaban : null,
                    isError : false,
                    errorMessage : "",
                    isEssay : false,
                    isFile : false,
                    pembahasan : null,
                    soalFile : null,
                    imgPembahasan : false,
                    textPembahasan : "",
                    choices : [
                        {
                            id : "A",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "B",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "C",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "D",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        }
                    ]
                })
        },
        hapusSoalList(index){
            this.fieldSoal.splice(index,1);
        },
        Simpan(){
          console.log(this.fieldSoal);
            if(this.type != null){
                this.loading = true
                this.Soal.validateSoaldanJawabanImla(this.fieldSoal,this.type)
                .then((res)=>{
                    if(res.haveError){
                        this.loading = false
                        this.fieldSoal = res.newFields
                    }else{
                      this.$store.dispatch('soal/saveImla',{soal:this.fieldSoal,type:this.type})
                      this.$router.push({ name: 'soalImla' })
                      this.loading = false
                    }
                })
                .catch(e=>{
                    this.loading = false;
                    console.log(e)
                })
            }else{
                this.$toasted.error("Paket wajib Dipilih", {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
            }
        },
        back(){
            this.$router.push({ name: 'soalImla' })
        },
        uploadSoal(event,id){
            this.fieldSoal[id].soal = event.target.files[0];
        },
        uploadSoalDua(event,id){
            this.fieldSoal[id].soal_dua = event.target.files[0];
        },
        uploadSoalTiga(event,id){
            this.fieldSoal[id].soal_tiga = event.target.files[0];
        },
        uploadJawaban(event,id){
            this.fieldSoal[id].jawaban = event.target.files[0];
        },
        uploadImageSoal(event){
            var formData = new FormData();
            formData.append('picture',event.target.files[0]);
            this.loading  = true;
            this.Soal.UploadFile(formData)
            .then(res=>{
                if(res.success){
                    this.fieldSoal[0].soal += `&nbsp;<img src="http://103.41.207.247:3000/${res.data}" alt="${res.data}" width="120" height="100" />`
                }else{
                this.$toasted.error("Upload Images Failed, Please Try Again Later", {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
                }
            this.loading  = false;
            this.$bvModal.hide('modal-image')
            })
            .catch(e=>{
            this.$bvModal.hide('modal-image')
                this.loading = false;
                console.log(e)
            })
        }
    },
}
</script>

<template>
  <Layout>
    <div v-if="loading" class="loading">
        <Circle8 size="70px"></Circle8>
    </div>
    <div class="row">
        <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">Add New Soal Imla</h4>
        </div>
        </div>
    </div>
    <div class="row">
        <button class="float btn btn-warning btn-gallery" v-b-modal.modal-image v-b-tooltip.hover title="Add Image to Soal"><i class="bx bx-image-alt font-size-18"></i></button>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <b-alert variant="danger" :show="isError">{{message}}</b-alert>
                    <div class="row  d-flex flex-column justify-content-start align-items-start">
                        <div class="col-md-5">

                            <div class="form-group">
                                <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="type">
                                    <option :value="null" hidden>Select Paket Soal</option>
                                    <option v-for="item in types" :key="item.id" :value="item.id">{{item.nama_paket}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-for="(item,index) in fieldSoal" :key="item.id+index">
                <div class="card-body">
                    <b-alert variant="danger" :show="item.isError">{{item.errorMessage}}</b-alert>
                    <div class="d-flex flex-row justify-content-between">
                        <!-- <h4 class="card-title">Soal Details Nomor ke {{index+1}}</h4> -->
                        <!-- <button class="btn btn-xs btn-danger" @click="hapusSoalList(index)"><i class="fas fa-times"></i> delete</button> -->

                    </div>

                    <!-- <div>
                      <div class="form-group">
                        <label class="control-label">Question : <span  class="text-danger">*</span></label>
                        <tinymce :id="`editor-${index}`" class="editor" height="500px"  v-model="item.soal" ></tinymce>
                      </div>
                    </div> -->
                    <div class="form-group" >
                    <label class="control-label">Upload Soal : <span class="text-danger">*file suara</span></label><br>
                    <input type="file" accept="*" @change="uploadSoal($event,index)" :id="`file-input-jawaban-${item.id}`">
                    </div>

                    <div class="form-group" >
                      <label class="control-label">Upload Soal 2 : <span class="text-danger">*file suara</span></label><br>
                      <input type="file" accept="*" @change="uploadSoalDua($event,index)" :id="`file-input-jawaban-${item.id}`">
                    </div>

                    <div class="form-group" >
                      <label class="control-label">Upload Soal 3 : <span class="text-danger">*file suara</span></label><br>
                      <input type="file" accept="*" @change="uploadSoalTiga($event,index)" :id="`file-input-jawaban-${item.id}`">
                    </div>

                    <div class="form-group" >
                    <label class="control-label">Upload Kunci Jawaban : <span class="text-danger">* file doc</span></label><br>
                    <input type="file" accept="*" @change="uploadJawaban($event,index)" :id="`file-input-jawaban-${item.id}`">
                    </div>
                    <br>
                    <br>
                    <div class="bg-light p-1">
                        <p>loading save file agak lama, jadi setelah save tunggu sampai muncul pesan sukses</p>
                    </div>
                </div>
            </div>
            <div class="form-group d-flex flex-row justify-content-between">
                <div>
                    <button class="btn btn-danger mx-2 " @click="back()">Kembali</button>
                    <button class="btn btn-primary mx-2" @click="Simpan()">Simpan</button>
                </div>
                <!-- <button class="btn btn-success flota-right" @click="TambahSoal()">Tambah Soal</button> -->
            </div>
                <b-modal id="modal-image" size="md" title="Upload Image For Insert to Soal">
                        <input type="file" accept="image/*" @change="uploadImageSoal($event)" id="fileSoal">
                    <template #modal-footer="{ ok, cancel }">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button size="sm" variant="success" @click="ok()">
                           <i class="bx bx-upload"></i> UPLOAD
                        </b-button>
                        <b-button size="sm" variant="danger" @click="cancel()">
                            Cancel
                        </b-button>
                    </template>
                </b-modal>
        </div>
    </div>
  </Layout>
</template>
